import React from "react"
// import "../css/main.css"
import Navbar from "./Navbar"
import Footer from "./Footer"
const Layout = ({ isHome = true, children }) => {
  return (
    <>
      <Navbar isHome={isHome} />
      {children}
      <Footer />
    </>
  )
}

export default Layout
