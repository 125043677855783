import React from "react"
import logo from "../assets/logo.svg"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

const Navbar = ({ isHome }) => {
  return (
    <nav className="nav-wrapper" id="home">
      <div className="wrapper">
        <header>
          <Link to="/">
            <img className="logo" src={logo} alt="Logo" />
          </Link>
          <nav>
            <ul>
              {!isHome && (
                <li role="button">
                  <Link to="/">Home</Link>
                </li>
              )}
              {isHome && <li onClick={() => scrollTo("#skills")}>Skills</li>}
              <li role="button">
                <Link to="/projects">Projects</Link>
              </li>
              <li role="button">
                <Link to="/uses">Uses</Link>
              </li>
              {isHome && <li onClick={() => scrollTo("#contact")}>Contact</li>}
            </ul>
          </nav>
        </header>
      </div>
    </nav>
  )
}

export default Navbar
