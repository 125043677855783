import React from "react"
import Emoji from "./emoji"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-text">
        Developed with{" "}
        <span>
          <Emoji symbol="❤" label="sheep" />
        </span>{" "}
        by Sadat Jubayer &copy; {new Date().getFullYear()}
      </div>
    </footer>
  )
}

export default Footer
